@font-face {
  font-family: 'Noir-Reg';
  // src: url('./fonts/NoirPro-Regular.eot');
  // src: url('./fonts/NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
  src: url('./fonts/NoirPro-Regular.woff2') format('woff2'),
  url('./fonts/NoirPro-Regular.woff') format('woff'),
  url('./fonts/NoirPro-Regular.ttf') format('truetype'),
  url('./fonts/NoirPro-Regular.svg#NoirPro-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal !important;
}

@font-face {
  font-family: 'Noir-Semi';
  // src: url('./fonts/NoirPro-SemiBold.eot');
  // src: url('./fonts/NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  src: url('./fonts/NoirPro-SemiBold.woff2') format('woff2'),
  url('./fonts/NoirPro-SemiBold.woff') format('woff'),
  url('./fonts/NoirPro-SemiBold.ttf') format('truetype'),
  url('./fonts/NoirPro-SemiBold.svg#NoirPro-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal !important;
}

@font-face {
  font-family: 'Noir-SemiBoldItalic';
  // src: url('./fonts/NoirPro-SemiBoldItalic.eot');
  // src: url('./fonts/NoirPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  src: url('./fonts/NoirPro-SemiBoldItalic.woff2') format('woff2'),
  url('./fonts/NoirPro-SemiBoldItalic.woff') format('woff'),
  url('./fonts/NoirPro-SemiBoldItalic.ttf') format('truetype'),
  url('./fonts/NoirPro-SemiBoldItalic.svg#NoirPro-SemiBoldItalic.svg') format('svg');
  font-weight: 400;
  font-style: normal !important;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  overflow: hidden;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  touch-action: manipulation;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}

html.is-mobile {
  overflow: hidden;
  #game {
    height: auto;
    z-index: 9;
    position: absolute;
    top: 34vw;
    width: 100%;
  }
  .game_header, .game_controls {
    display: flex;
  }

  body {
    position: fixed;
    overflow: hidden;
  }

  body {
    position: fixed;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}

#controller {
  width: 16vh;
  height: auto;
  left: -12%;
  top: 61%;
  transform: translateY(-50%);
  position: absolute;
}

.spr {
  max-width: 100%;
  background-size: 100%;
  background-image: url('images/html/sprite.png');
}

.btn-up, .btn-down {
  padding-top: calc(532 / 795 * 100%);
}

.btn-jump {
  padding-top: calc(616 / 1385 * 100%);
}

.game_controls {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35vh;
  touch-action: manipulation;
}

.game_controls > * {
  touch-action: manipulation;
}

.game_controls-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.game_controls-left, .game_controls-right {
  position: relative;
  width: 20%;
  height: 100%;
}

.game_controls-left:before {
  content: "";
  display: block;
  background: url(images/html/baseBG-left.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game_controls-right:before {
  content: "";
  display: block;
  background: url(images/html/baseBG-right.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game_controls-centre {
  width: 91%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin: 0 -1px;
  &:before {
    content: "";
    display: block;
    background: url(images/html/baseBG-middle.png) repeat-x;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  position: relative;
}

.game_control {
  position: absolute;
  width: 100%;
  height: auto;
}

#jump-btn {

  width: 40%;
  top: 62%;
  right: -5%;
  z-index: 2;
  transform: translateY(-50%);
}

#up-btn, #down-btn {
  position: relative;
}

#up-btn {
  top: 0;
  left: 0;
  z-index: 2;
}

#down-btn {
  bottom: 0;
  left: 0;
  z-index: 2;
}

#game {
  text-align: center;
}

.game_header {
  display: none;
  position: absolute;
  width: 100%;
  height: 50vw;
  max-height: 250px;
  top: 0;
  z-index: 3;

  .game--link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 55%;
    height: 40px;
    z-index: 5;
    margin: auto;
  }

  .game_header-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .game_header-middle {
    position: relative;
    width: 91%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    margin: 0 -1px;
    &:before {
      content: "";
      display: block;
      background: url(images/html/topBG-middle.png) repeat-x;
      background-size: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: "";
      display: block;
      background: url(images/html/topLogo.png) no-repeat center;
      background-size: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .game_header-left, .game_header-right {
    position: relative;
    width: 20%;
    height: 100%;
  }

  .game_header-left:before {
    content: "";
    display: block;
    background: url(images/html/topBG-left.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .game_header-right:before {
    content: "";
    display: block;
    background: url(images/html/topBG-right.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#wrapper.landscape .rotate {
  display: flex;
}

.rotate {
  text-align: center;
  content: "";
  display: none;
  background: url(images/html/rotateBG.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  img {
    height: 80vh;
    width: auto;
  }
}

.btn-up, .btn-down {
  transform: scale(.75);
  position: relative;
}

.btn-up {
  bottom: -2vw;
}

.btn-down {
  top: -2vw;
}

.btn-jump {
  background-position: 0 0%;
  background-size: 100%;
}

#jump-btn.active {
  background-position: 0 32.450331%;
  background-size: 100%;
}

#down-btn.active {
  background-position: 0 56.160458%;
  background-size: 115.789474%;
}

.btn-up {
  background-position: 0 85.386819%;
  background-size: 115.789474%;
}

#up-btn.active .btn-up {
  background-position: 0 100%;
  background-size: 115.789474%;
}

#down-btn.active .btn-down {
  background-position: 0 70.773639%;
  background-size: 115.789474%;
}

.btn-down {
  background-position: 0 56.160458%;
  background-size: 115.789474%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .game_header {
    display: none;
  }
  html.is-mobile #game {
    top: 0;
    transform: translateY(0);
  }
}

html.is-tablet {
  #game {
    background: url(images/html/tablet-border-top.png) no-repeat;
  }
  #controller {
    width: 17vh;
  }
  #jump-btn {
    width: 29%;
  }

  .btn-jump {
    padding-top: calc(616 / 1985 * 100%);
  }
  #controller {
    top: 60%;
  }
}

@media (max-width: 375px) and (max-height: 575px) {
  .game_controls {
    bottom: -5%;
  }
}


@media (width: 712px) and (height: 970px) {
  #game{
    top: 23vw !important;
  }
}


/**************************\
  Basic Modal Styles
\**************************/
.modal {
  font-family: sans-serif;
  position: relative;
  display: none;
  z-index: 99999;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  font-family: Noir-Reg;
  text-rendering:optimizeLegibility;
  position: relative;
  background-color: #fff;
  padding: 15px;
  max-width: 600px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
  @media (max-width: 475px) {
    padding: 30px 45px;
  }
}

.modal__header {
  text-align: center;
  position: relative;
  .pc-logo {
    width: 100%;
    max-width: 160px;
    margin-bottom: 20px;
  }

  .pccc-logo {
    width: 100%;
    max-width: 260px;
    margin-bottom: 20px;
  }

}

.modal__title {
  margin: auto;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #ed3023;
  box-sizing: border-box;
}

.modal__close {
  position: absolute;
  top: 15px;
  right: 10px;
  background: transparent;
  border: 0;
  font-size: 30px;
  z-index: 30;

  &:before {
    content: "\2715";
  }
}

.modal__content {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
  &.is-open {
    display: block;
  }
  &[aria-hidden="false"] {
    .modal__overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal__container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  &[aria-hidden="true"] {
    .modal__overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    .modal__container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  .modal__container, .modal__overlay {
    will-change: transform;
  }
}

.pc-form {
  /*display: flex;*/
  margin-bottom: 35px;
}

.input-number {
  width: 15%;
  display: inline-block;
}

.field {
  background-color: #e9f0fd;
  border: none;
  height: 40px;
  width: 31%;
  padding: 0 1%;
  font-size: 16px;
}

#submit {
  width: 100%;
  background-color: black;
  border: none;
  color: white;
  height: 45px;
  font-size: 16px;
  -webkit-appearance: button;
}

.modal__content p {
  font-size: 13px;
}

.signup__card {
  text-align: center;
}

.pc-red {
  color: #ed3023;
}

.checkbox-group {
  margin: 25px auto;
  text-align: center;

  .checkbox-group__text {
    margin: 0 auto;
    display: inline-block;
  }

  .game_rules {
    margin: 0 auto;
  }
}

.modal-centre {
  text-align: center;
}

.field-4-digit {
  width: 15%;
}

.input-group {
  label {
    text-transform: uppercase;
    width: 15%;
    display: inline-block;
    margin: 0 1%;
    min-width: 50px;
    
    @media screen and (min-width: 600px) {
      width: 19%;
      text-align: right;
      margin: 0;
    }
  }
  input {
    margin: 0 1%;
  }
}

.input-number {
  text-transform: uppercase;
  font-size: 13px;
  position: absolute;
  margin-top: -20px;
  padding-left: 17.9%;
  
  @media screen and (min-width: 600px) {
    padding-left: 13.5%;
    margin-top: 10px;
  }
}

.modal .signup__logo {
  max-width: 50%;
}

#pc-congrats p {
  max-width: 60%;
  margin: auto;
  padding-bottom: 10px;
}

.modal__btn {

  font-size: 14px;
  padding: 1rem 1rem;
  background-color: black;
  margin-top: 10px;
  color: white;
  border-radius: 0;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modal__footer {
  margin-top: 30px;
}

.signup__logo.logo-oops {
  max-width: 35%;
}

.input-group input.error {
  background-color: #FAECE9;
}

.error_message {
  display: none;
  color: #EA332D;
  font-size: 12px;
  padding-left: 17.9%;
  
  @media screen and (min-width: 600px) {
    padding-left: 13.5%;
    margin-top: 5px;
  }
}

.error_message.error {
  display: block;
}

.game_rules {
  display: none;
  padding: 5px;
  background-color: #FAECE9;
  color: #ED3023;
  font-size: 12px;
}

.game_rules.error {
  display: inline-block;
}

.game_rules img {

  max-width: 15px;
  position: relative;
  top: 2px;
  margin: 0 6px;
}

/* Card Instruction Image */
.form-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    min-height: 99.11px;
  }

  .error_message.error {
    position: absolute;
  }
}

.card-instructions {
  max-width: 50%;
  margin: 20px auto 0;

  @media screen and (min-width: 600px) {
    max-width: 33%;
    margin: 0;
  }
}

.card-instructions img {
  max-width: 100%;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid black;
}

.modal__container
{
  font-family: Noir-Reg;
}

.modal__container em
{
  font-family: 'Noir-SemiBoldItalic';
  font-style: normal !important;
  font-weight: 400 !important;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modal__title {
  font-family: 'Noir-Reg';
  font-size: 18px;
  font-weight:400;
}

.signup__card {
  font-family: 'Noir-Reg';
  font-size: 20px;
  line-height: 1.2em;
}

.signup__card strong {
  font-family: 'Noir-SemiBoldItalic';
}

.pc-congratulations .modal__container {
  .modal__title {
    font-size: 30px;
  }
}

.points_number {
  width: 140px;
  padding-bottom: 20px;
}

.modal__content p {
  line-height: 1.2em;
}

.modal__btn-next {
  background-color: #d93022;
  color: white;
}

.modal__footer-2 {
  display: flex;
}

.modal__footer-2 .modal__btn {
  flex: 50%;
  margin: 0 5px;
}